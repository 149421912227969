// menu 模块接口列表示例
import {
    resquest
} from './base'
import axios from '../http' // 导入http中创建的axios实例 
import Qs from 'qs'


export const jn_scuser_vote = {
    scuser_vote_pager_get_list(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_vote_pager/get_list`, data2);
    },
    scuser_vote_pager_getinfo(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_vote_pager/getinfo`, data2);
    },
    scuser_vote_pager_record_get_list(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_vote_pager_record/get_list`, data2);
    },
    scuser_vote_pager_record_getinfo(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_vote_pager_record/getinfo`, data2);
    },
    scuser_vote_pager_record_get_add(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_vote_pager_record/get_add`, data2);
    },
    scuser_vote_pager_record_tijiao_do(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_vote_pager_record/tijiao_do`, data2);
    },
    scuser_vote_pager_record_del_do(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_vote_pager_record/del_do`, data2);
    },
} 
